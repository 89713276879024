<template>
  <div>
    <CrudIndex
      v-bind="this.$store.state.stone_model.shop_product_pre_order"
      @title-bar-custom-btn-click="$_titleBarCustomBtnClick($event)"
      createUrl="/shop_product_pre_order/create"
      :getUpdateUrl="$_getUpdateUrl"
      @params="$_onParams($event)"
    ></CrudIndex>
    <WsPopup
      ref="importPopup"
      title="以xlsx匯入商品庫存"
    >
      <template #content>
        <div>
          <WsText
            v-if="state.file"
            class="mb-20"
          >{{state.file.name}}</WsText>
          <input
            v-show="false"
            @change="$_onChange($event)"
            type="file"
            ref="fileInput"
            accept="*/*"
          >
          <WsBtn @click="$_onUploadClick()">引入檔案</WsBtn>
        </div>
      </template>
      <template #rightActions>
        <WsBtn @click="$_importPopupClose()">取消</WsBtn>
        <WsBtn
          @click="$_submit()"
          :loading="loading.file"
        >確認</WsBtn>
      </template>

    </WsPopup>
  </div>
</template>
<script>
export default {
  data: () => ({
    state: {
      file: null,
    },
    loading: {
      file: false,
    },
    searchParams: null,
  }),
  methods: {
    async $_titleBarCustomBtnClick($event) {
      if ($event === "import-product") {
        this.$_importPopupOpen();
      } else if ($event === "export-product") {
        const url = "shop_product/export/excel/signedurl";
        let params = { ...this.searchParams };
        let hasFilter = false;
        for (let key in this.searchParams) {
          if (this.searchParams[key] === 0 || this.searchParams[key]) {
            hasFilter = true;
          }
        }
        if (!hasFilter) {
          params.get_all = true;
        }
        try {
          const res = await this.$axios.get(url, { params });
          window.open(res.data, "_blank");
        } catch (err) {
          alert(err);
        }
      }
    },
    $_onUploadClick() {
      this.$refs.fileInput.click();
    },
    async $_onChange($event) {
      this.state.file = $event.target.files[0];
    },
    async $_submit() {
      if (!this.state.file) return;
      try {
        this.loading.file = true;
        const formData = new FormData();
        formData.append("file", this.state.file);
        await this.$axios.post(`/shop_product/import/excel`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.loading.file = false;
        this.$_importPopupClose();
      } catch (err) {
        alert(err);
        this.loading.file = false;
        this.$_importPopupClose();
      }
    },
    $_importPopupOpen() {
      this.$refs.importPopup.open();
    },
    $_importPopupClose() {
      this.$refs.importPopup.close();
    },
    $_onParams($event) {
      this.searchParams = $event;
    },
    $_getUpdateUrl(item) {
      return `/shop_product_pre_order/${item.id}/update`;
    },
    getReadUrl(item) {
      return `/shop_product_pre_order/${item.id}/read`;
    },
  },
};
</script>